/* eslint-disable react/jsx-filename-extension */
import { graphql } from 'gatsby';
import { useQueryParam } from 'gatsby-query-params';
import PropTypes from 'prop-types';
import React from 'react';
import PageHeader from '../components/pageheader';
import SEO from '../components/seo';
import BolsaoForm from '../containers/bolsao-form';
import ContactFormArea from '../containers/contact-form-area';
import Footer from '../containers/Layout/Footer';
import Header from '../containers/Layout/Header';

const Bolsao = ({ data, location, pageContext }) => {
  const { scholarshipYear } = data.site.siteMetadata;
  const utmSource = useQueryParam('utm_source', '');
  const utmMedium = useQueryParam('utm_medium', '');
  const utmCampaign = useQueryParam('utm_campaign', '');

  const marketingCampaigns = {
    utmSource,
    utmMedium,
    utmCampaign,
  };

  return (
    <>
      <SEO
        title={`Curso Preparatório Garra - Bolsão ${scholarshipYear}`}
        description="Cursos Preparatórios para Vestibulares e Concursos Militares - Garra"
        location={location}
      />
      <Header />
      <PageHeader pageContext={pageContext} location={location} title={`Bolsão ${scholarshipYear}`} />
      <ContactFormArea
        form={<BolsaoForm scholarshipYear={scholarshipYear} marketingCampaigns={marketingCampaigns} />}
      />
      <Footer />
    </>
  );
};

Bolsao.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        scholarshipYear: PropTypes.number.isRequired,
      }),
    }),
  }).isRequired,
};

export const query = graphql`
  query ScholarshipYearQuery {
    site {
      siteMetadata {
        scholarshipYear
      }
    }
  }
`;

export default Bolsao;
